.form-container{

	display: flex;
    flex-direction: column;
    height: 80vh;
}

.scroll-container{
	flex: 1;
    overflow: auto;
}