.encabezado-doc{
    margin-bottom: 1cm;
    letter-spacing: 0.04em;
    line-height: normal !important;
}
.logo-encabezado{
    width: 2cm; 
    float: left;
}
.primera-linea{
    font-size: 12pt !important;
}
.sengunda-linea{
    font-size: 11pt;
}

.nombre-doc{
    ont-size: 10pt;
}