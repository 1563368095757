@import "../../../../style/configs/color.css";

.menu a{
        display: block;
}
.v-menu{
    overflow: hidden;
    position: absolute;
    background: $menu;
    width: 200px;
    min-height: calc(100vh - 80px);
    -webkit-transition: width 0.4s;
    -moz-transition: width 0.4s;
    -ms-transition: width 0.4s;
    -o-transition: width 0.4s;
    transition: width 0.4s;
}
.v-menu.collapsed{
    width: 0;
}

.v-menu-sucursal{
    border-bottom: $menu 2px solid;
    color: $primario !important;
}
.v-opcion{
    color: $hover;
    padding: 10px 25px;
}
.v-opcion.active{
    color: $primario;
}


.sidebar {
  position: fixed;
  top: 61px;
  width: 200px;
  bottom: 0;
  background-color: $menu;
  padding-top: 15px;
  z-index: 500;
  height: calc(100% - 61px);
  overflow-y: auto;
  overflow-x: hidden;
  .img-activo {
    display: none;
  }
  .activo {
    color: #fff;
    background-color: $secundario;
    font-weight: normal;
    border-left: 4px solid $hover;
    
  }
  
}



.side-desk, .sidebar{
  .img-activo {
    display: none;
  }

  /*****MOSTRAR ICONO BLANCO CUANDO: EL TIEM ESTÁ SELECCIONADO, EN HOVER Y MENÚ EXPANDIDO*/
  .activo, [class*="sidenav---navitem---"]:hover, [class*="sidenav---highlighted"].expandible  {
    .img-activo {
      display: inline;
    }
    .img-inactivo {
      display: none;
    }
  }
}

.sidebar img.sub-item {
    margin-right: 5px;
}
.sidebar img {
  width: 35px;
}

.sidebar-item {
  border-left: 4px solid transparent;
  display: grid;
  grid-template-columns: 35px 1fr;
  align-items: center;
  padding: 5px 13px;
  color: $hover;
}

.imagen-sidebar {
  img {
    box-shadow: 0 0 3px 0 rgba(0, 0, 0, 1);
    border-radius: 50%;
    width: 90px;
  }
}
