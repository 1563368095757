@import "../../../../../style/configs/color.css";
/****side menu**/

/******Menú acordeon expandido o nav item activo****/
[class*="sidenav---expanded"] > [class^="sidenav---navitem"], .activo {
  border-left: 6px solid $primario !important;
  background-color: $secundario;
}
/******letra blanca en menú expandido*/
[class*="sidenav---highlighted"].expandible span {
    color: $menu !important;
}

.nav-text-activo{
   background-color: $secundario;
}
/** Link seleccionado**/
[class^="sidenav---selected--"] a, 
[class*="sidenav---selected--"]  a, .activo{
  color:$menu !important;
}

.nav-text-activo span {
    color: $menu !important;
}

/*Item seleccionado*/
[class*="sidenav---selected--"][class*="sidenav-subnavitem--"]
{
  border-left: none !important;
  background-color: $secundario!important ;
}

/**Color de fondo del menu**/
[class*="sidenav---sidenav--"]
{
  background-color: $menu !important;
  /*transition: all 700ms;*/
}
/***/
.side-desk a {
    color: $texto_general !important;
    font-weight: bold !important;
}

[class*="sidenav---selected--"] a, .activo a  {
    color: white !important;
    font-weight: bold !important;
}
[class*="sidenav---navitem---"]:hover {
     background-color: $secundario!important ;
}

[class*="sidenav---navitem---"]:hover span, [class*="sidenav---navitem---"]:hover a {
    color: #ffff !important;
}

img.icon-menu {
    width: 2em;
}

.submenu{
  background-color: $hover !important;
  color: white;
}

.submenu a {
    color: white !important;
    font-weight: bold;
    font-size: 1em;
}


.side-desk button, .nav-header{
    background-color: $hover !important;
    height: 74px;
    /*transition: all 700ms;*/
}

 



.nav-header{

  white-space: nowrap;
  color: #fff;
}

.nav-header img{
    max-height: 58px;
    padding: 0 15px;   
}

.nav-header-mostar{
  display: block;
  /*transition: all 700ms;*/
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.nav-header-oculta{
  display: none;
  /*transition: all 700ms;*/
}

.cusam-logo{
    vertical-align: middle;
    color: white;
    font-weight: bold;
}

.letra-pequenia{

  font-size: 0.9em !important;
}