@import "../../../../../style/configs/color.css";


.load-mask {
    position: relative;

}

.full-size{
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    border: 0;
    margin: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
}

.load-mask-content.blur {
    /* -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    backface-visibility: hidden;
    filter: blur(0);
    transition: filter 0.2s ease-out; */
}

.load-mask-content.blur.loading {
    filter: blur(5px);
}

.loader-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 64px;
}

.loader-container p {
    font-size: 1.3rem;
    margin: 0;
}

.loader-container.radius {
    border-radius: 10px;
}

.loader-container.dark {
    background: $fondo;
}

.loader-container.light {
    background: rgba(255, 255, 255, 0.5)
}


.side-expanded .loader-container {
    padding-left: 240px !important;
}


@media screen and (max-width: 600px) {
      .loader-container {
        padding-left: 0 !important;
    }
}

.progress{
    width: 80%;
    border: 0.5px solid #d8d3d3;
}