.linea {
        text-transform: uppercase;
        font-weight: bold;
}

.interlineado td{
	padding-bottom: 2mm;
}
.letra-mediana{
	 text-transform: uppercase;
	 font-size: 7.7pt !important;
}
.letra-grande{
	 text-transform: uppercase;
	 font-size: 8.5pt !important;
}

td.letra-firmas{
	font-size: 7.5pt !important 
}
.margen-abajo{
	padding-bottom: 20mm;
}