@import "../../../../style/configs/color.css";
/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.u-position-absolute {
    position: absolute;
}

.u-position-relative {
    position: relative;
}

.accordion {
    border-radius: 2px;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__item--has-icon {
    position: relative;
}

.accordion__title {
    cursor: pointer;
    padding-top: 2px;
    padding-bottom: 2px;
    width: 100%;
    border: none;
    position: relative;
}

.accordion__title div{
        border-left: 4px solid transparent;
    display: grid;
    grid-template-columns: 35px 1fr;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 13px;
    color: #014955;
}

.accordion__title em {
    margin-top: 3px;
}

.accordion__title:hover {
    text-decoration: underline;
}
.accordion__title:focus{
    outline: none !important;
}
.accordion__body {
    display: block;
    animation: fadein 0.35s ease-in;
    padding: 2.5px;
    margin-left: 2.5em;
    color: $texto_general;
}
.accordion__body a {
    color: $menu !important;
}

.accordion__body--hidden {
    display: none;
    opacity: 0;
    animation: fadein 0.35s ease-in;
}

.accordion__title > *:last-child,
.accordion__body > *:last-child {
    margin-bottom: 0;
}
.accordion__body .sidebar-item{
    padding-top: 4px;
    padding-bottom: 4px;
    border-left: 4px solid $hover;
    text-align: left;
}

.accordion__arrow {
    display: inline-block;
    position: relative;
    width: 24px;
    height: 12px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -6px;
}

.accordion__arrow::after,
.accordion__arrow::before {
    display: block;
    position: absolute;
    top: 50%;
    width: 10px;
    height: 2px;
    background-color: currentColor;
    content: '';
}

.accordion__arrow::before {
    left: 4px;
    transform: rotate(45deg);
}

[aria-expanded='true'] .accordion__arrow::before, [aria-selected='true'] .accordion__arrow::before {
    transform: rotate(-45deg);
}

.accordion__arrow::after {
    right: 4px;
    transform: rotate(-45deg);
}

[aria-expanded='true'] .accordion__arrow::after, [aria-selected='true'] .accordion__arrow::after {
    transform: rotate(45deg);
}

.accordion__arrow::before,
.accordion__arrow::after {
    transition: transform 0.25s ease, -webkit-transform 0.25s ease;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes move-down {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(5px);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes move-up {
    0% {
        transform: translateY(0);
    }
    10% {
        transform: translateY(0);
    }
    20% {
        transform: translateY(-5px);
    }
    30% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(0);
    }
}

.accordion__title--animated:hover .accordion__arrow {
    animation-name: move-down;
    animation-duration: 1.5s;
}

.accordion__title--animated[aria-expanded='true']:hover .accordion__arrow {
    animation-name: move-up;
    animation-duration: 1.5s;
}

.blue-hover{
    color: $hover;
}
.activo .blue-hover{
    color: $primario;
}
.blue-hover:hover{
    color: #0056b3;
}
.activo .blue-hover:hover{
    color: $primario;
}



.list-items {
    background: #014955;
}


.activo .menu-item{
    color: $menu;
}

.profile-item:focus{
    outline: -webkit-focus-ring-color auto 0px !important;
}
.profile-item.activo em{
    color: white !important;
}