.tabla-print-only td, .tabla-print-only th {
	 border: solid 0.5px black;
	 padding-left: 4pt ;
}

.margen-1{

	margin: 1cm;
}



.encabezado-hoja{
	position: relative;
}


.pie-pagina{
	position: absolute;
	bottom: -26cm;
	font-size: 7pt;
}
