@import "../../../../../style/configs/color.css";


.toolbar{
    color: white;
    font-size: 23px;
    font-weight: bold;
}
.toolbar img{
    max-width: 20px;
}
.toolbar .btn{
    border: white solid 2px;
    color: white;
}
.toolbar .btn:hover{
    border: white solid 2px;
}
.toolbar .btn a:hover{
    text-decoration: none;
}

.toolbar-search span{
  color: $texto_general;
}
.toolbar-search img{
    max-width: 20px !important;
}

.toolbar-search {
    font-size: 18px;
}
.toolbar-search .fa{
    right: 20px !important;
}
.toolbar-search input{
    background-color: white !important;
    color: $texto_general !important;
}
.toolbar-search input:focus{
    background-color: white !important;
    color: $texto_general !important;
}
.toolbar .filtro{
    font-size: 15px;
    font-weight: normal;
}
.contenedor-search{
    position: relative;
    display: flex;
}
.contenedor-search input[type="text"] {
    flex: 1;
}
.contenedor-search button {
    position: absolute;
    right: 1px !important;
    top: 0px !important;
    background: $hover !important;
    position: absolute;
    top: 0px;
    height: calc(2.1rem + 4px);
    width: 4em;
    border: 0px;
}
.titulo{
    justify-content: left;
    padding-left: 2em;
    color: $primario;
    font-weight: bold;
}
.subtitulo{
    padding-left: 6px;
    padding-top: 5px;
    font-weight: 200;
    font-style: italic;
    text-transform: uppercase;
    color: $texto_general;
}
.search{
    padding-left: 0px;
}
@media only screen and (max-width: 768px){
    .titulo{
        justify-content: center;
    }
    .search{
        padding-left: 6px;
        padding-right: 6px;
    }
}
@media only screen and (max-width: 576px) {
    .titulo{
        justify-content: center;
        padding: 0px;
        width: 100%;
    }
    .titulo h3{
        margin: 0px;
    }
    .subtitulo{
        padding: 0px;
        width: 100%;
        display: block;
        justify-content: center;
        text-align: center;
    }
    .search{
        padding-left: 6px;
        padding-right: 6px;
    }
}
